<template lang="pug">

div(fluid)
  
  v-container.justify-center.aling-center(style="margin-bottom: 3em; margin-top: 3em")
    v-row.justify-center.aling-center
      v-col( cols=12 md=5 )

        bloque_arbitros( :titulo='adjudicadores_registro_de_árbitros.titulos.arbitros' :arreglo='adjudicadores_registro_de_árbitros.árbitros')
      div.d-flex.flex-column.align-center
        .lineamedia_arbitraje( style='width:2px')
      v-col( cols=12 md=5 )

        bloque_arbitros( :titulo='adjudicadores_registro_de_árbitros.titulos.arbitros_extranjeros' :arreglo='adjudicadores_registro_de_árbitros.arbitros_internacionales')
     
        bloque_arbitros( :titulo='adjudicadores_registro_de_árbitros.titulos.adjudicadores_dispute_boards' :arreglo='adjudicadores_registro_de_árbitros.adjudicadores_dispute_boards').mt-10
     

</template>
<script>
export default {
  components: {
    bloque_arbitros: ()=>import('./bloque_arbitros')
  }
}
</script>



